import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import CityCard from '@Pages/Deals/components/CityCard'
import { useAuth } from '@/services/Auth'
import AppModal from '@/shared/components/AppModal'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import OnPageNotification from '@/shared/components/OnPageNotification'
import moment from "moment/moment";

function MySavedTrips () {

  const { t } = useTranslation()

  const auth = useAuth()
  const [processing, setProcessing] = useState(true)
  const [sortBy, setSortBy] = useState('price')

  const [showModal, setShowModal] = useState(false)
  const [tripToRemove, setTripToRemove] = useState(false)
  const [trips, setTrips] = useState([])

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const handleConfirm = () => {
    setShowModal(false)
    if (tripToRemove) {
      removeTrip()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    setTripToRemove(null)
  }

  const goToBook = () => {

  }
  const removeTripRequest = (trip) => {
    setTripToRemove(trip)

    setModalTitle(t('pages.trips.notifications.remove_trip.title'))
    setModalBody(t('pages.trips.notifications.remove_trip.body'))
    setConfirmText(t('common.dialogs.actions.remove'))
    setCancelText(t('common.dialogs.actions.cancel'))
    setShowModal(true)
  }
  const removeTrip = () => {
    setProcessing(true)
    auth.deleteRequest(`${LOCATION.TRIPS.API.path}/${tripToRemove.id}`)
      .then(response => {
        auth.checkToken();
        setTripToRemove(null)
        setProcessing(false)
        getTrips()

      })
      .catch(error => {
        setProcessing(false)
      })
  }
  const getTrips = () => {
    setProcessing(true)
    return auth.getRequest(`${LOCATION.TRIPS.API.path}`, {
      sort_by: sortBy,
    })
      .then(response => {
        setTrips(response.data.data)
        setProcessing(false)
      })
      .catch(error => {
        setProcessing(false)
      })
  }

  const findNewTrip = (trip) => {
    localStorage.setItem('trip_values', JSON.stringify({
      holiday_type: trip.trip_request.holiday_type_id,
      budget: [trip.trip_request.min_budget, trip.trip_request.max_budget],
      when: {
          request_dates: null,
          months: null,
          how_long: trip.trip_request.how_long,
          allow_flexi_duration: trip.trip_request.allow_flexi_duration
      },
      where_from: trip.trip_request.airports.map(_ => ({ value: _?.id, label: _?.name, name: _?.name })),
      where_to: trip.trip_request.locations.map(_ => ({ value: _?.id, label: _?.name, name: _?.name })),
      who: {
          adults: trip.trip_request.adults,
          children: trip.trip_request.children,
          children_ages: trip.trip_request.children_ages,
          rooms: trip.trip_request.rooms
      },
      filters: trip.trip_request.filters
    }));

    window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`;
  }

  const togglePriceChangeNotifications = (trip) => {
    setProcessing(true);
    auth.postRequest(`${LOCATION.TRIPS.API.path}/${trip.id}/toggle-price-change-notifications`)
        .then(() => {
            setProcessing(false);
            setTrips(trips.map(t => t.id === trip.id ? {
                ...t,
                subscribed_to_price_change_notifications: !t.subscribed_to_price_change_notifications
            } : t));
        })
        .catch(() => {
            setProcessing(false);
        });
  }

  useEffect(() => {
    let request = getTrips()
    return () => request?.abort && request.abort()
  }, [sortBy])

  if (processing) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="container p-md-2 p-4">
        {
          auth.user.user_type ==='travel_lite' &&
          <Row className="my-4">
            <p>{t('pages.trips.travel_light_member_trip_saving_statement')}</p>
          </Row>
        }
        <Row className="my-4">
          <Col className="col-md-4 col-12 d-grid gap-2">
            <Link to={LOCATION.TRIPS.START_YOUR_JOURNEY.path}
                  className="px-4 btn btn-primary text-dark heading-text">{t('pages.trips.buttons.start_your_journey')}</Link>
          </Col>
        </Row>
        {
          trips?.length === 0 ?
            <OnPageNotification
              notificationTitle={t('pages.trips.notifications.no_trips_found.title')}
              notificationDescription={t('pages.trips.notifications.no_trips_found.body')}
              notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            />
            :
            <>
              <Row className="my-4">
                <Col className="col-md-4 col-12">
                  <Form.Control
                    as="select"
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value)
                    }}
                  >
                    <option value="price">{t('pages.trips.select_options.sort_by_price')}</option>
                    <option value="date">{t('pages.trips.select_options.sort_by_date')}</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                {
                  trips?.map(trip => {
                    return (
                      <Col className="col-lg-4 col-md-6 col-12 mb-4" key={trip.id}>
                        <CityCard
                          deal={trip}
                          fullCard={false}
                          dealCard={true}
                          goToBook={goToBook}
                          removeTrip={removeTripRequest}
                          bookNowText={t('pages.trips.buttons.check_current_price')}
                          showDate={true}
                          findNewTrip={findNewTrip}
                          togglePriceChangeNotifications={togglePriceChangeNotifications}
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            </>
        }

      </div>
    </>
  )

}

export default MySavedTrips