import React, { useRef, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import PageHeader from '@/layouts/components/PageHeader'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import steps from '../Request/process_steps/steps'
import { useState } from 'react'
import LOCATION from '../../../constants/Location'
import { Link, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import LocationSelection from '../Request/components/LocationSelection'

function WhereTo ({ holidayTypes, modelEditor, handleCancel, handleConfirm }) {

  const { t } = useTranslation()
  const history = useHistory()

  let step = steps.find(_ => _.name === 'where-to')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

  const holidayType = holidayTypes.find(_ => _.id === tripValues?.holiday_type)

  let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/when`
  let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/where-from`

  const continueActionRef = useRef(null)

  const schema = yup.object().shape({
    where_to: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_destination'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_to.title') })),
        label: yup.string(),
      }))
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: tripValues,
    resolver: yupResolver(schema),
  })

  const nextStep = (values) => {
    localStorage.setItem('trip_values', JSON.stringify(values))
    if (!modelEditor) {
      history.push(nextStepLink)
    } else if (handleConfirm) {
      handleConfirm()
    }
  }

  useEffect(() => {
    if( !storeValues ){
      history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path);
    }
  }, [])

  return (
    <>
      {
        !modelEditor &&
        <>
          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="primary"
            textColor="dark"
            prefixElements={[]}
            suffixElements={[]}
          />
          <div className="w-100 sm-bg d-block d-sm-none" style={
            {
              backgroundImage: `url("${holidayType?.featured_image_url}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          }>
          </div>
          <PageHeader
            className="d-block d-sm-none"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
        !modelEditor ?
          {
            backgroundImage: `url("${holidayType?.featured_image_url}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          } : null
      }>
        <div className="mt-sm-5 mt-0 p-sm-5 trip-process-form">
          <div className="d-grid gap-2">
            <Button
              variant="dark"
              className="text-white heading-text"
              onClick={() => nextStep({ ...tripValues, ...{ where_to: null } })}>{`Search all ${holidayType?.name} destinations`}</Button>
            {holidayType?.search_all_text?.length > 0 && <p className="text-center">{holidayType?.search_all_text}</p>}
          </div>
          <div className="line-separator separator my-3 text-uppercase">{t('common.or')}</div>
          <p className="text-center">{t('pages.trips.steps.where_to.form_heading')}</p>
          <Form noValidate onSubmit={handleSubmit(nextStep)}>
            <LocationSelection
              errors={errors}
              unregister={unregister}
              reset={reset}
              setValue={setValue}
              register={register}
              selectedLocations={tripValues?.where_to}
              holidayType={holidayType}
            />

            <Form.Group className="my-4 d-grid gap-2">

              {
                modelEditor ?
                  <div className="d-flex justify-content-between w-100">
                    <Button
                      variant="secondary"
                      className="heading-text me-2"
                      onClick={() => handleCancel()}
                    >
                      {t('common.buttons.cancel')}
                    </Button>
                    <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.save')}</span>
                    </Button>
                  </div> :
                  <>
                    <Button ref={continueActionRef} className="fw-bold d-block heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.continue')}</span>
                    </Button>
                    {
                      !modelEditor &&
                      <Link to={backStepLink} className="btn btn-light-1 btn-lg fw-bold d-block mt-2 heading-text" type="button">
                        <span className="text-uppercase">{t('common.buttons.back')}</span>
                      </Link>
                    }
                  </>
              }
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  )
}

export default WhereTo