import PageHeader from '@/layouts/components/PageHeader'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Modal, Row } from 'react-bootstrap'

import { faArrowLeftLong, faArrowRightLong, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useGet from '@/hooks/useGet'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import { useRef } from 'react'
import LoadingButton from '@/shared/components/LoadingButton'
import { useAuth } from '@/services/Auth'
import Location from '@/constants/Location'
import { Link, useHistory } from 'react-router-dom'
import StepControl from './StepControl'
import steps from '../Request/process_steps/steps'
import moment from 'moment'
import { isValidData } from '@/utility'
import AppModal from "@/shared/components/AppModal";

function Summary ({
  holidayTypes,
  showTravelMaxBanner,
  continueWithDeal,
  requestComplete,
}) {

  const { t } = useTranslation()
  const history = useHistory()

  const auth = useAuth()
  let storeValues = localStorage.getItem('trip_values') ?? null
  let step = steps.find(_ => _.name === 'summary')

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

  let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/who-is-going`

  const {
    data: airports,
    loading
  } = useGet(`${LOCATION.AIRPORTS.API.path}?ids=${tripValues?.where_from?.map(_ => _.value).join(',')}`)

  const [serverError, setServerError] = useState(null)
  const [stepToEdit, setStepToEdit] = useState(null)
  const [sendingRequest, setSendingRequest] = useState(false)

  const [travelMaxPremiumBanner, setTravelMaxPremiumBanner] = useState(false)

  const saveActionRef = useRef(null)
  const continueActionRef = useRef(null)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  const getAirports = () => {
    let from_locations = []
    if (airports) {
      airports.forEach(_ => {
        if (tripValues?.where_from.map(_ => _.value).includes(_.id)) {
          from_locations.push(_.name)
        }
      })
    }
    return from_locations.join(', ')
  }

  const getLocations = () => {
    if (tripValues?.holiday_type === 'surprise') {
      return t('pages.trips.steps.holiday_type.types.surprise')
    }
    if (!tripValues?.where_to) {
      return `Any ${holidayTypes.find(_ => _.id === tripValues?.holiday_type)?.name} Break`;
    }

    return tripValues?.where_to?.map(_ => _.name).join(', ')
  }

  const getDates = () => {
    let dates = []
    if (tripValues.when?.by_specific_date) {
      tripValues?.when?.request_dates?.forEach((dateRanges) => {
        if (dateRanges) {
          dates.push([moment(dateRanges[0])?.format(t('common.formats.short_date')), moment(dateRanges[1])?.format(t('common.formats.short_date'))].join( ' - ' ))
        }
      })
    } else {
      tripValues?.when?.months.forEach(month => {
        if (month) {
          dates.push(month)
        }
      })
    }

    return dates.join(', ')
  }

  const editSteps = (step) => {
    setStepToEdit(step)
  }

  const handleCancel = () => {
    setStepToEdit(null)
  }

  const handleConfirm = () => {
    setTripValues(JSON.parse(localStorage.getItem('trip_values')))
    setStepToEdit(null)
  }

  const requestTrip = () => {
    if (auth.user) {
      sendTripRequest()
    } else {
      localStorage.setItem('request_data', JSON.stringify(tripValues))
      setModalTitle(t('common.notifications.skip_login_sign_up_on_search.title'))
      setModalBody(t('common.notifications.skip_login_sign_up_on_search.body'))
      setConfirmText(t('common.travel_max_feature.buttons.sing_up_now'))
      setCancelText(t('common.travel_max_feature.buttons.login'))
      setShowModal(true)
    }
  }

  const prepareRequestBody = () => {
    return {
      holiday_type_id: tripValues?.holiday_type,
      min_budget: parseInt(tripValues?.budget[0]),
      max_budget: parseInt(tripValues?.budget[1]),
      trip_dates: tripValues?.when?.request_dates?.filter(_ => typeof _ === 'object').map(_ =>  [moment(_[0]).format('YYYY-MM-DD'), moment(_[1]).format('YYYY-MM-DD')]),
      months: tripValues?.when?.months.filter(_ => _ !== null),
      how_long: tripValues?.when?.how_long,
      allow_flexi_duration: tripValues?.when?.allow_flexi_duration,
      where_from: tripValues?.where_from?.map(_ => _.value),
      where_to: tripValues?.where_to === 'surprise' ? null : tripValues?.where_to?.map(_ => _.value),
      adults: tripValues?.who?.adults,
      children: tripValues?.who?.children == 0 ? null : tripValues?.who?.children,
      children_ages: tripValues?.who?.children_ages,
      rooms: tripValues?.who?.rooms,
      filters: tripValues?.filters,
      star_rating: tripValues?.star_rating ?? null,
    }
  }
  const sendTripRequest = () => {
    setServerError(null)
    setSendingRequest(true)
    auth.postRequest(`${Location.TRIPS.REQUEST_API.path}`, prepareRequestBody())
      .then(response => {
        setSendingRequest(false)
        requestComplete(response.data?.data)
      })
      .catch(error => {

        setServerError(error.response.data.message)
        setSendingRequest(false)
      })
  }
  const handleDialogConfirm = () => {
    window.location.href = `${LOCATION.AUTH.SIGN_UP.path}`
  }

  const handleDialogCancel = async () => {
    window.location.href = `${LOCATION.AUTH.SIGN_IN.path}`
  }

  useEffect(() => {
    showTravelMaxBanner(false)
    if (continueWithDeal) {
      sendTripRequest()
    }
  }, [continueWithDeal])

  useEffect(() => {
    localStorage.removeItem('request_data')
  }, [])

  useEffect(() => {
    if (!storeValues || !isValidData(JSON.parse(storeValues))) {
      history.replace(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
    }
  }, [])

  if (loading) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleDialogConfirm}
        handleCancel={handleDialogCancel}
      />
      <PageHeader
        className="d-none d-sm-flex"
        title={t(step.title)}
        bgColor="primary"
        textColor="dark"
        prefixElements={[]}
        suffixElements={[]}
      />

      <PageHeader
        className="d-block d-sm-none"
        title={t(step.title)}
        bgColor="dark"
        textColor="white"
      />

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
        {
          backgroundImage: `url("/assets/images/bg/${step.bg}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
      }>
        {
          stepToEdit ?
            <Modal show={!!stepToEdit} onHide={handleCancel}>
              <Modal.Header closeButton>
                <Modal.Title className="heading-text">{t(stepToEdit.title)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StepControl
                  step={stepToEdit}
                  holidayTypes={holidayTypes}
                  modelEditor={true}
                  saveActionRef={saveActionRef}
                  handleConfirm={handleConfirm}
                  handleCancel={handleCancel}
                />
              </Modal.Body>
            </Modal>
            :
            null
        }

        <div className="mt-sm-5 mt-0 p-sm-5 p-3 trip-process-form">
          {
            steps.map(currentStep => {
              return (currentStep.name !== 'summary' && currentStep.name !== 'flight_options') ? (
                <Row className="mb-3" key={currentStep.name}>
                  <Col className="col-10">
                    <h6
                      className="heading-text text-uppercase my-1">{t(`pages.trips.steps.summary.step_headings.${currentStep.name.replace(/-/g, '_')}`)}</h6>
                    {
                      currentStep.name === 'budget' && <span>{t('pages.trips.steps.summary.values.budget_summary', {
                        min: tripValues?.budget[0],
                        max: tripValues?.budget[1]
                      })}</span>
                    }
                    {
                      currentStep.name === 'holiday-type' &&
                      <span>{
                        tripValues?.holiday_type === 'surprise' ? t('pages.trips.steps.holiday_type.types.surprise') :
                          holidayTypes?.find(_ => _.id === tripValues?.holiday_type)?.name
                      }</span>
                    }
                    {
                      currentStep.name === 'where-from' &&
                      <span>{getAirports()}</span>
                    }
                    {
                      currentStep.name === 'where-to' &&
                      <span>{getLocations()}</span>
                    }
                    {
                      currentStep.name === 'who-is-going' &&
                      <span>
                        {t('pages.trips.steps.summary.values.adults', { count: tripValues.who?.adults })}
                        {
                          (tripValues.who?.children && tripValues.who?.children?.length !== 0) ?
                            <>
                              ,&nbsp;
                              {t('pages.trips.steps.summary.values.children', { count: tripValues.who?.children })},&nbsp;
                              ({t('pages.trips.steps.summary.values.children_ages', { count: tripValues.who?.children_ages?.join(', ') })})
                            </>
                            : null
                        }

                      </span>
                    }
                    {
                      currentStep.name === 'when' &&
                      <span>
                        {getDates()},&nbsp;{t('pages.trips.form.labels.when.night', { count: tripValues.when?.how_long })}
                      </span>
                    }
                    {
                        currentStep.name === 'what-will-make-it-special' &&
                        (
                          <div>
                            <span>
                              {tripValues?.filters?.map(f => f.label)?.join(', ')}
                            </span>

                            {
                              tripValues?.star_rating >= 1 && (
                                <>
                                  <span>
                                    Filtering hotels by star rating {tripValues?.star_rating} and above
                                  </span>
                                </>
                              )
                            }
                            {
                                tripValues?.star_rating === null && (
                                    <span>
                                        We will filter hotels that are well rated
                                    </span>
                                )
                            }
                          </div>
                        )
                    }
                  </Col>
                  <Col className="col-2 text-end edit-btn">
                    <FontAwesomeIcon role="button" onClick={() => editSteps(currentStep.name)} icon={faPencil}/>
                  </Col>
                </Row>
              ) : null
            })
          }
          <div className="d-grid gap-2 mt-5">
            <LoadingButton
              clickRef={continueActionRef}
              loading={sendingRequest}
              variant="dark"
              className="heading-text"
              titleTranslationKey="pages.trips.buttons.find_me_a_deal"
              onSubmit={() => requestTrip()}
            />
          </div>


          {serverError && <p className="text-danger my-3">{t(`pages.trips.errors.${serverError}`)}</p>}
        </div>
      </div>
    </>
  )
}

export default Summary