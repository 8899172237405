import React, {useRef, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '@/layouts/components/PageHeader'
import { Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import AirportSelection from '../Trips/Request/components/AirportSelection'
import moment from 'moment'
import MonthsSelection from '../Trips/Request/components/MonthsSelection'
import LoadingButton from '@/shared/components/LoadingButton'
import RoomSelection from '../Trips/Request/components/RoomSelection'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { formatRecord } from '@/utility'
import AppModal from '@/shared/components/AppModal'
import BudgetSelection from '../Trips/Request/components/BudgetSelection'
import TravelMaxPremium from '../Banners/TravelMaxPremium'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";

function Preferences () {
  const { t } = useTranslation()
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [loading, setLoading] = useState(false)
  const [preferences, setPreferences] = useState(auth.user?.preferences)
  const [maxSelectedBudget, setMaxSelectedBudget] = useState(preferences?.budget ?? [0, 2500])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [dealTypes, setDealTypes] = useState([]);

  const months = moment.months()

  const schema = yup.object().shape({
    where_from: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_from.title') })),
        label: yup.string(),
      })),
    budget: yup.array()
      .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.max_budget.title') })),
    rooms: yup.array().of(yup.object().shape({
      adults: yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
      children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
      children_ages: yup.array()
          .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
          .when(['children'], function (children, schema) {
            return children && children != 0 ?
                schema.of(yup.number()
                    .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
                    .transform((value) => (isNaN(value) ? undefined : value)))
                : schema.nullable()
          })
    })),
    star_rating: yup.number().nullable(),
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    unregister,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      min_budget: preferences?.budget[0],
      max_budget: preferences?.budget[1],
      where_from: preferences?.airports?.slice(0, Math.min(2, preferences?.airports?.length)).map(formatRecord) ?? [],
      where_to: preferences?.locations.map(formatRecord) ?? [],
      holiday_types: preferences?.holiday_types.map(formatRecord) ?? [],
      favourite_months: preferences?.favourite_months?.map(_ => `${_}`) ?? [],
      receive_deals_on_email: preferences?.receive_deals_on_email,
      adults: preferences?.adults,
      children: preferences?.children == 0 ? null : preferences?.children,
      children_ages: preferences?.children_ages ?? [],
      rooms: preferences?.rooms?.length === 0 ? [{
        adults: null,
        children: 0,
        children_ages: [],
      }] : (preferences?.rooms ?? []),
      deal_emails_to_receive: preferences?.deal_emails_to_receive ?? [],
      star_rating: preferences?.star_rating,
    },
    resolver: yupResolver(schema),
  })

  const savePreferences = (values) => {

    setLoading(true)
    auth.postRequest(`${LOCATION.PROFILE.PREFERENCES_API.path}`, {
      min_budget: parseInt(values?.budget[0]),
      max_budget: parseInt(values?.budget[1]),
      favourite_months: values?.favourite_months,
      where_from: values?.where_from?.map(_ => _.value),
      adults: values.rooms.reduce((acc, room) => acc + room.adults, 0),
      children: values.rooms.reduce((acc, room) => acc + room.children, 0),
      children_ages: values.rooms.reduce((acc, room) => acc.concat(room.children_ages), []).filter(_ => _ !== null),
      rooms: values?.rooms,
      receive_deals_on_email: values?.receive_deals_on_email,
      deal_emails_to_receive: values?.deal_emails_to_receive,
      long_haul_airports: values?.long_haul_airports?.map(_ => _.value),
      star_rating: values?.star_rating,
    })
      .then(response => {
        setLoading(false)

        let nextUpdate = response.data?.data?.next_deals_update?.[0];
        const nextMonday = moment().day(1).add(1, 'week');

        setModalTitle(t('pages.profile.notifications.preference_updated.title'))
        setModalBody(t('pages.profile.notifications.preference_updated.body', { nextUpdateDate: moment(nextUpdate?.next_update ?? nextMonday).format(t('common.formats.short_date')) }))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)

      })
      .catch(error => {
        setLoading(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  const getDealTypes = (search) => {

    requests.deal_types && requests?.deal_types?.abort && requests.deal_types.abort()

    return new Promise((resolve, reject) => {
      requests.deal_types = auth.getRequest(LOCATION.DEALS.DEAL_TYPES.path, { search })

      requests.deal_types.then(response => {
        resolve(response?.data?.map(formatRecord))
      })
          .catch(error => reject(error))
    })
  }

  useEffect(() => {
    getDealTypes().then(response => {
      setDealTypes(response)
    })
  }, [])

  const receiveDealOnEmail = watch('receive_deals_on_email');
  const starRating = watch('star_rating');

  useEffect(() => {
    if (receiveDealOnEmail && !preferences.deal_emails_to_receive) {
      setValue('deal_emails_to_receive', dealTypes?.map(_ => _.id));
    }
    if (!receiveDealOnEmail && preferences.deal_emails_to_receive) {
      setValue('deal_emails_to_receive', []);
    }
  }, [receiveDealOnEmail]);

  if (auth.user?.user_type !== 'travel_max') {
    return <TravelMaxPremium/>
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <PageHeader
        title={t('pages.profile.preferences')}
      />
      <div className="container px-4">
	  
	  	<div className="profile-preferences float-left w-full block">
			<div className="float-left w-full block text-center opening-block">
				<p>{t('pages.profile.adjust_your_preferences_to_find_perfect_holidays')}</p>
			</div>
			
			<div className="profile-preference-columns">
                <form noValidate onSubmit={handleSubmit(savePreferences)}>
                    <div className="row budget-deals">
                        <div className="fields budget-bar">
                            <p>{t('pages.trips.steps.budget.total_budget_statement', {
                                min: maxSelectedBudget[0],
                                max: maxSelectedBudget[1],
                                currency: auth.user.currency.symbol
                            })}</p>
                            <BudgetSelection
                                errors={errors}
                                setValue={setValue}
                                values={preferences}
                                setMaxBudget={setMaxSelectedBudget}
                                currency={auth.user.currency.symbol}
                            />
                        </div>

                        <div className="fields">
                            <div class="deals-toggle">
                                <Form.Check
                                    inline
                                    label={t('pages.profile.form.labels.receive_deals_on_email')}
                                    id="receive_deals_on_email"
                                    name="receive_deals_on_email"
                                    {...register('receive_deals_on_email')}
                                />
								
								<span className="option yes">Yes</span>
								<span className="option no">No</span>
                            </div>

                            {
                                receiveDealOnEmail && (
                                    <div className="deals-full-width" key={receiveDealOnEmail}>
                                        <label
                                            className="request-process-heading mb-2">{t('pages.profile.deal_emails_to_receive')}</label>

                                        {
                                            dealTypes?.map((dealType, index) => (
                                                <Form.Check
                                                    key={index}
                                                    inline
                                                    label={dealType.label}
                                                    id={`deal_emails_to_receive_${dealType.id}`}
                                                    name={`deal_emails_to_receive_${dealType.id}`}
                                                    value={dealType.id}
                                                    checked={!!watch(`deal_emails_to_receive`)?.includes(dealType.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setValue('deal_emails_to_receive', [...getValues('deal_emails_to_receive'), dealType.id])
                                                        } else {
                                                            setValue('deal_emails_to_receive', getValues('deal_emails_to_receive').filter(_ => _ !== dealType.id))
                                                        }
                                                    }}
                                                />
                                            ))
                                        }

                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="splitter"></div>

                    <div className="col left-block">
                        <div className="fields preferred-airport">
                            <label
                                className="request-process-heading mb-1">{t('pages.trips.steps.where_from.single_form_heading')}<span
                                className="text-danger">*</span></label>
                            <AirportSelection
                                errors={errors}
                                register={register}
                                unregister={unregister}
                                reset={reset}
                                setValue={setValue}
                                selectedAirports={preferences?.airports?.slice(0, Math.min(2, preferences?.airports?.length)).map(formatRecord)}
                                controlled={true}
                                smallTextLabel={true}
                            />
                        </div>


                        <div className="fields room-selection">
                            <RoomSelection
                                register={register}
                                unregister={unregister}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                                rooms={preferences?.rooms ?? []}
                            />
                        </div>
                    </div>

                    <div className="col right-block">
                        <div className="fields long-haul-flights">
                            <label>Long Haul Airports</label>
                            <AirportSelection
                                errors={errors}
                                register={register}
                                unregister={unregister}
                                reset={reset}
                                setValue={setValue}
                                selectedAirports={preferences?.long_haul_airports?.slice(0, Math.min(2, preferences?.long_haul_airports?.length)).map(formatRecord)}
                                controlled={true}
                                smallTextLabel={true}
                                holidayType={4}
                                fieldName="long_haul_airports"
                            />
                            <p className="last">Please be aware that if your budget is set too low, you may not receive
                                any long-haul deals if none are available within that range.</p>
                        </div>
                        <div className="fields favourite-month">
                            <MonthsSelection
                                errors={errors}
                                months={months}
                                register={register}
                                favouriteMonths={preferences?.favourite_months}
                            />
                        </div>
						
						<div className="fields preference-star-rating">
                            <Form.Group className="mt-4">
                                <p>Filter Hotels by Star Rating</p>
                                {
                                    [1, 2, 3, 4, 5].map((star, index) => (
                                        <div key={index} className="d-inline-block me-2"
                                             onClick={() => setValue('star_rating', star === starRating ? null : star)}>
                                            <FontAwesomeIcon
                                                className={"me-2 star-rating-icon" + (starRating >= star ? ' selected' : '')}
                                                icon={faStar}/>
                                        </div>
                                    ))
                                }
                                {
                                    starRating && (
                                        <span className="d-block mt-2">
                            We will filter hotels that are rated {starRating} stars and above
                        </span>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>

                    <div className="splitter"></div>


                    
                    <div className="row">
                        <Form.Group className="preference-submit">
                            <LoadingButton
                                className="auth-btn text-uppercase fw-bold text-white heading-text"
                                loading={loading}
                                variant="primary"
                                titleTranslationKey="common.buttons.save"
                                type="submit"
                            />
                        </Form.Group>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </>
)
}

export default Preferences